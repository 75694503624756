import downloadListURls from 'constants/download';
import Link from 'next/link';
import Image from 'next/image';
import appleWhite from 'assets/image/apple_white.png';
import androidWhite from 'assets/image/android_white.png';
import appleBlack from 'assets/image/apple_black.png';
import androidBlack from 'assets/image/android_black.png';
import usePlatform from 'hooks/usePlatform';
import { downloadReportExposure, downloadReportClick } from 'utils/downloadReport';
import classnames from 'classnames';
import { useMemo, useRef, useState } from 'react';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

const PlatformButton = ({
  color,
  link,
  type,
  position = 'homepage',
}: {
  color: 'default' | 'white' | 'black';
  type: 'AppStore' | 'GooglePlay';
  link: string;
  full?: boolean;
  position?: string;
}) => {
  const [isHoving, setIsHoving] = useState(false);

  const colorClassName = useMemo(() => {
    if (color === 'white') {
      return `border-[0px] bg-white text-black hover:bg-black hover:text-white`;
    }
    if (color === 'black') {
      return `border-[0px] bg-black text-white hover:bg-white hover:text-black`;
    }
    return 'border-[rgba(255,255,255,0.16)] bg-transparent text-white hover:bg-ab-orange';
  }, [color]);

  const appStoreSrc = useMemo(() => {
    if (isHoving) {
      if (color === 'white') {
        return appleWhite;
      }
      if (color === 'black') {
        return appleBlack;
      }
      return appleWhite;
    }

    if (color === 'white') {
      return appleBlack;
    }
    if (color === 'black') {
      return appleWhite;
    }
    return appleWhite;
  }, [color, isHoving]);

  const androidStoreSrc = useMemo(() => {
    if (isHoving) {
      if (color === 'white') {
        return androidWhite;
      }
      if (color === 'black') {
        return androidBlack;
      }
      return androidWhite;
    }

    if (color === 'white') {
      return androidBlack;
    }
    if (color === 'black') {
      return androidWhite;
    }
    return androidWhite;
  }, [color, isHoving]);

  return (
    <Link
      href={link}
      rel="nofollow"
      target="_blank"
      title="AppStore Download"
      className={classnames(
        'my-0 flex h-[72px] w-[251px] cursor-pointer items-center justify-center rounded-[36px] border border-solid hover:border-[0px] hover:border-transparent',
        colorClassName
      )}
      style={{
        backdropFilter: 'blur(20px)',
      }}
      onClick={() => {
        downloadReportClick({
          position,
        });
      }}
      onMouseEnter={() => setIsHoving(true)}
      onMouseLeave={() => setIsHoving(false)}
    >
      {type === 'AppStore' && (
        <>
          <Image
            // eslint-disable-next-line no-nested-ternary
            src={appStoreSrc}
            alt="apple app icon"
            width={48}
            height={48}
            className="mr-2.5"
          />
          <p className="text-xs">
            GET IT ON <br />
            <span className="text-xl">App Store</span>
          </p>
        </>
      )}
      {type === 'GooglePlay' && (
        <>
          <Image
            // eslint-disable-next-line no-nested-ternary
            src={androidStoreSrc}
            alt="android app icon"
            width={48}
            height={48}
            className="mr-2.5"
          />
          <p className="text-xs">
            GET IT ON <br />
            <span className="text-xl">Google Play</span>
          </p>
        </>
      )}
    </Link>
  );
};

const Download = ({
  link,
  color = 'default',
  position = 'homepage',
}: {
  color: 'default' | 'white' | 'black';
  link: string;
  full?: boolean;
  position?: string;
}) => {
  const ref = useRef<HTMLAnchorElement>(null);

  useIntersectionObserver({
    ref,
    callback: () => {
      downloadReportExposure({
        position,
      });
    },
  });

  const colorClassName = useMemo(() => {
    if (color === 'white') {
      return 'bg-ab-orange text-white';
    }
    if (color === 'black') {
      return 'bg-black text-white';
    }
    return 'bg-ab-orange text-white';
  }, [color]);

  return (
    <Link
      href={link}
      ref={ref}
      rel="nofollow"
      target="_blank"
      title="Download"
      className={classnames(
        'flex h-[3.75rem] w-full items-center justify-center rounded-[8vw] text-[5.12vw]',
        colorClassName
      )}
      onClick={() => {
        downloadReportClick({
          position,
        });
      }}
    >
      {$t({ defaultMessage: 'Download', id: '5q3qC0' })}
    </Link>
  );
};

const PlatformDownload = ({
  color = 'default',
  link,
  full,
  position,
}: {
  color?: 'default' | 'white' | 'black';
  link: string;
  full?: boolean;
  position?: string;
}) => {
  const { platform } = usePlatform();
  const ref = useRef<HTMLDivElement>(null);

  useIntersectionObserver({
    ref,
    callback: () => {
      downloadReportExposure({
        position,
      });
    },
  });

  if (!platform) {
    return null;
  }

  if (platform === 'iOS' || platform === 'Android') {
    return <Download color={color} link={link} full={full} position={position} />;
  }

  return (
    <div ref={ref} className="flex lg:gap-[20px]">
      <PlatformButton
        full={full}
        color={color}
        type="AppStore"
        position={position}
        link={downloadListURls.ios}
      />
      <PlatformButton
        full={full}
        color={color}
        type="GooglePlay"
        position={position}
        link={downloadListURls.android}
      />
    </div>
  );
};

export default PlatformDownload;
